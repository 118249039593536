/* Base styles for the OTP verification container */
.otp-verification-container {
    max-width: 400px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    text-align: center;
  }
  
  .otp-verification-container h2 {
    font-size: 24px;
    margin-bottom: 20px;
    color: #333;
  }
  
  .otp-verification-container form {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .otp-verification-container label {
    font-size: 16px;
    margin-bottom: 10px;
    color: #555;
  }
  
  .otp-verification-container input {
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
  }
  
  .otp-verification-container button {
    width: 100%;
    padding: 10px;
    border: none;
    border-radius: 4px;
    background-color: #4CAF50;
    color: white;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .otp-verification-container button:hover {
    background-color: #45a049;
  }
  
  .error-message {
    color: red;
    margin-top: 10px;
  }
  
  /* Responsive styles */
  @media (max-width: 600px) {
    .otp-verification-container {
      padding: 15px;
    }
  
    .otp-verification-container h2 {
      font-size: 20px;
    }
  
    .otp-verification-container input, .otp-verification-container button {
      font-size: 14px;
    }
  }
  