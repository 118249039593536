/* DashboardPage.css */

.dashboard-page {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  text-align: center;
}

.dashboard-page h1 {
  margin-bottom: 20px;
}

.dashboard-page p {
  margin-bottom: 10px;
}

.dashboard-page .profile-photo {
  margin-top: 20px;
}

.dashboard-page .profile-photo img {
  max-width: 100%;
  height: auto;
  border-radius: 50%;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  margin-top: 10px;
}

.dashboard-page button {
  padding: 10px 20px;
  margin-top: 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.dashboard-page button:hover {
  background-color: #0056b3;
}




html, body {
    height: 100%;
    margin: 0;
    padding: 0; 
    overflow-y: scroll;
  }
  
  .Predgree-onobaording {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    background: #ffffff;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    height: 100%;
    overflow-y: scroll;
  }
  
 
  .Predgree-onobaording h1 {
    text-align: center;
    margin-bottom: 20px;
    color: #0a57e6;
  }
  
  .board-pre {
    display: flex;
    flex-wrap: wrap;
  }
  
  .board-pre .column {
    flex: 1;
    min-width: 300px;
    padding: 10px;
    box-sizing: border-box;
  }
  
  .board-pre .divider {
    flex-basis: 100%;
    height: 2px;
    background: #ddd;
    margin: 20px 0;
  }
  
  .onboading-pre-degree {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
    color: #000000;
  }
  
  .board-pre input[type="text"],
  .board-pre input[type="file"],
  .board-pre select {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    margin-bottom: 15px;
    box-sizing: border-box;
  }
  
  .board-pre input[type="file"] {
    padding: 5px;
  }
  
  .board-pre button[type="button"],
  .board-pre button[type="submit"] {
    padding: 10px 15px;
    border: none;
    background-color: #007bff;
    color: rgb(255, 255, 255);
    border-radius: 5px;
    cursor: pointer;
    margin-top: 10px;
  }
  
  .board-pre button[type="button"]:hover,
  .board-pre button[type="submit"]:hover {
    background-color: #0056b3;
    color: #ffffff;
  }
  
  .subject-grades {
    margin-bottom: 20px;
  }
  
  .subject-grade {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .subject-grade input[type="text"] {
    flex: 1;
    margin-right: 10px;
  }
  
  .subject-grades button {
    background-color: #28a745;
  }
  
  .subject-grades button:hover {
    background-color: #218838;
  }
  
  /* Mobile Styles */
  @media (max-width: 768px) {
    .board-pre {
      flex-direction: column;
    }
  
    .board-pre .column {
      min-width: 100%;
    }
  
    .subject-grade {
      flex-direction: column;
    }
  
    .subject-grade input[type="text"] {
      margin-right: 0;
      margin-bottom: 10px;
    }
  
    .board-pre button[type="button"],
    .board-pre button[type="submit"] {
      width: 100%;
      margin: 5px 0;
    }
  }
  
  .gen-onboard-pre {
    margin: 20px;
  }
  
  .gen-onboard-pre header {
    text-align: center;
    font-size: 24px;
    margin-bottom: 20px;
  }
  
  .onboard-pre-board {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 20px;
  }
  
  .column {
    border: 1px solid #ccc;
    padding: 20px;
    border-radius: 8px;
    text-align: center;
  }
  
  .column h2 {
    margin-bottom: 10px;
  }
  
  .column button {
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .column button:hover {
    background-color: #0056b3;
  }
  
  .column p {
    margin-top: 10px;
  }
  

  .scholarship-page {
    max-width: 600px;
    margin: 0 auto;
}

input, select, textarea {
    margin-bottom: 10px;
    width: 100%;
}

button {
    margin-top: 10px;
    padding: 8px 16px;
    background-color: #007bff;
    color: #fff;
    border: none;
    cursor: pointer;
}

button:hover {
    background-color: #0056b3;
}

button:focus {
    outline: none;
}

p {
    font-weight: bold;
}


/* Container styling */
.dashboard-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px;
  font-family: 'Arial, sans-serif';
}

/* Heading styling */
.dashboard-container h1 {
  font-size: 24px;
  color: #333;
}

.dashboard-container h2 {
  font-size: 20px;
  color: #555;
}

/* Paragraph styling */
.dashboard-container p {
  font-size: 16px;
  color: #666;
}

/* User photo styling */
.user-photo {
  border-radius: 50%;
  overflow: hidden;
  width: 100px;
  height: 100px;
  margin: 20px 0;
}

.user-photo img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* Button styling */
.dashboard-button {
  background-color: #007BFF;
  color: #FFF;
  border: none;
  padding: 10px 20px;
  margin: 10px 0;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.dashboard-button:hover {
  background-color: #0056b3;
}

/* Mobile responsiveness */
@media (max-width: 600px) {
  .dashboard-container {
    padding: 10px;
  }

  .dashboard-container h1 {
    font-size: 20px;
  }

  .dashboard-container h2 {
    font-size: 18px;
  }

  .dashboard-container p {
    font-size: 14px;
  }

  .dashboard-button {
    width: 100%;
    padding: 15px;
    font-size: 18px;
  }
}




.payment-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px;
  font-family: 'Arial, sans-serif';
}

.payment-container h1 {
  font-size: 24px;
  color: #333;
}

.payment-container p {
  font-size: 16px;
  color: #666;
}

.payment-options {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.payment-options label {
  margin: 10px 0;
  font-size: 16px;
  color: #333;
}

.program-course {
  margin-bottom: 20px;
}

.program-course label {
  font-size: 16px;
  color: #333;
}

.program-course select {
  margin-left: 10px;
  padding: 5px;
  font-size: 16px;
}

.payment-button {
  background-color: #007BFF;
  color: #FFF;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.payment-button:hover {
  background-color: #0056b3;
}
.admission-status-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px;
  font-family: 'Arial, sans-serif';
}

.admission-status-container h1 {
  font-size: 24px;
  color: #333;
}

.admission-status-container p {
  font-size: 16px;
  color: #666;
}

.admission-status-button {
  background-color: #007BFF;
  color: #FFF;
  border: none;
  padding: 10px 20px;
  margin-top: 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.admission-status-button:hover {
  background-color: #0056b3;
}


/* AdminApprovalStatusPage.css */

.admin-approval-status-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.admin-approval-status-container h1 {
  text-align: center;
  margin-bottom: 20px;
}

.admin-approval-status-container table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.admin-approval-status-container th,
.admin-approval-status-container td {
  padding: 10px;
  border: 1px solid #ddd;
  text-align: left;
}

.admin-approval-status-container th {
  background-color: #f2f2f2;
}

.admin-approval-status-container tbody tr:nth-child(even) {
  background-color: #f2f2f2;
}

.admin-approval-status-container tbody tr:hover {
  background-color: #ddd;
}

.admin-approval-status-container button {
  padding: 5px 10px;
  margin-right: 5px;
  cursor: pointer;
}

.admin-approval-status-container p {
  text-align: center;
}


/* ViewDetailsPage.css */

.view-details-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.view-details-container h1 {
  text-align: center;
  margin-bottom: 20px;
}

.view-details-container p {
  margin-bottom: 10px;
}

.view-details-container .actions {
  margin-top: 20px;
  text-align: center;
}

.view-details-container button {
  padding: 5px 10px;
  margin-right: 5px;
  cursor: pointer;
}



